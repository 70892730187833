import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageLoader from "../../components/loader/PageLoader";
import useAxiosInterceptors from "../../axios/useAxios";
import { findUser, mergeAccounts, saveUser } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../firebase/firebaseConfig.js";
import mailLogo from "../../styles/img/mail.svg";
import logoGoogle from "../../styles/img/google.svg";
import HandleGoogle from "./login/HandleGoogle.jsx";
import HandleFacebook from "./login/HandleFacebook.jsx";
import PikkoPointHeader from "../../components/card/PikkoPointHeader";
import BrandList from "../brand/BrandList";
import ScanFooter from "./ScanFooter";
import NavigationMenu from "../../components/slider/NavigationMenu";
import {
  ACCOUNT,
  HOME,
  INVITATION,
  NUMBER_SCREEN,
  PROMO,
  SCAN,
  SET_ACCOUNT,
} from "../../constants/routes";
import BackButton from "../../components/button/BackButton.jsx";
import inscriptionHeader from "../../styles/img/account/inscriptionHeader.svg";
import connexionHeader from "../../styles/img/account/connexionHeader.svg";
import UserInfo from "../../components/UserInfo.jsx";
import Email from "./login/Email.jsx";
import GetUserInfo from "./GetUserInfo.jsx";
import fid_card from "../../styles/img/fid_card.svg";
import { authenticate } from "../../actions/authenticateActions";
import { notifyWarning } from "../../utils/Notify";
import SwipperTuto from "../../components/Swipper";
import step1 from "../../styles/img/tuto/step1.svg";
import step2 from "../../styles/img/tuto/step2.svg";
import step3 from "../../styles/img/tuto/step3.svg";
import questionMark from "../../styles/img/question-mark.svg";
import tel from "../../styles/img/tel.svg";
import { isLessThan_Minutes } from "../../utils/Validator";
import { isAppPWA } from "../../utils/helper-functions.js";
import { useUserLoginStatus } from "../../utils/hooks.js";
import { createCart } from "../../actions/cartActions";
import { getStore } from "../../actions/storeActions";
import { FooterAccount } from "../../components/footerAccount.jsx"
const AccountScreen = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { prevPage,numberSreenPrevPage } = useLocation().state || "";
  const resetEmail = useLocation().state?.resetEmail;
  const navigate = useNavigate();
  const axiosInstance = useAxiosInterceptors();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const user = useSelector((state) => state.user);
  const [state, setState] = useState("");
  const { cartItems } = useSelector((state) => state.cart);
  const [forgot, setForgot] = useState(false);
  const [brands, setBrands] = useState([]);
  const [showNavigationMenu, setShowNavigationMenu] = useState(false);
  const [loadingConnexion, setLoadingConnexion] = useState(false);
  const [fidCompany, setFidCompany] = useState(false);
  const [fidExist, setFidExist] = useState(false);
  const [previousNumberScreen, setPreviousNumberScreen] = useState("");
  const [isSms, setIsSms] = useState(false);
  const refComposant = useRef();

  // hooks
  const isPWA = isAppPWA();
  const isLoggedIn = useUserLoginStatus();

  useEffect(() => {
    if (isLoggedIn && isPWA && !user.favoriteStore) {
      navigate(SET_ACCOUNT);
    }
  }, [isPWA, isLoggedIn]);

  useEffect(() => {
    auth.onAuthStateChanged(async (firebaseUser) => {
      setLoadingConnexion(true);
      try{
        if(localStorage.getItem("fusionFirstAccount") && firebaseUser && firebaseUser.email && firebaseUser.displayName){
          let type =""
          localStorage.removeItem("fusionFirstAccount");
          if(firebaseUser.displayName){
            type = "google"
          }
          else{
            type = "email"
          }
          const result = await mergeAccounts(axiosInstance, user._id, firebaseUser, type,dispatch,user,navigate);
          if (result) {
            navigate(ACCOUNT);
          }
          setLoadingConnexion(false);
      }
      else if (user && user._id) {
        setLoadingConnexion(false);
      } else if (firebaseUser && firebaseUser.email && firebaseUser.displayName){
        await GetRedirectInfo(auth, user);
      }
      else {
        setLoadingConnexion(false);
      }
      }catch(e){
        console.log(e)
        setLoadingConnexion(false)
      }

    });
  }, []);
  const GetRedirectInfo = async (auth, user) => {
    try {
      const data = await GetUserInfo(auth, user);
      authenticate(
        axiosInstance,
        data.newResult.profile.email,
        data.result.user.accessToken
      )
        .then(() => {
          dispatch(
            saveUser(data.newResult.profile, axiosInstance, cartItems, navigate)
          );
        })
        .catch((error) => {
          setLoadingConnexion(false)
          if (error.response.data.errorCode === "USER_FID_CARD_ALREADY_USED") {
            notifyWarning(
              "Oups",
              "L'email ne correspond à la carte de fidélité",
              "Ok"
            );
          } else {
            notifyWarning(
              "Oups",
              "Une erreur est survenue lors de ta connexion.",
              "Ok"
            );
          }
          console.error("Authenticate", error);
        })
        .finally(() => setLoadingConnexion(false));
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de l'authentification avec Google:",
        error
      );
    }
  };
  useEffect(async () => {
    window.scrollTo(0, 0);
    console.log("USER", user);
    console.log("authCURRENTUSER", auth.currentUser);
    if(numberSreenPrevPage && numberSreenPrevPage === INVITATION){
      setPreviousNumberScreen(numberSreenPrevPage);
    }
    if (user && user._id) {
      if(!localStorage.getItem("fusionFirstAccount")){
        setLoadingConnexion(false);
      }
      axiosInstance.get("/brands").then((response) => {
        setBrands(response.data);
      });
      await dispatch(findUser(user, axiosInstance, navigate));
      if (isPWA && user && user._id && (!cartItems || !cartItems._id)) {
        const orderCreate = { storeId: user?.favoriteStore || "", clientId: user?._id || "" };
        await dispatch(createCart(orderCreate, axiosInstance));
        // await dispatch(getStore(user.favoriteStore, axiosInstance));
      }
    }
  }, []);

  useEffect(() => {
    // REVIEW : Dans cette classe il y a 3 useEffect qui écoutent la même variable (user)
    if (user && user.email) {
      setEmail(user.email);
    }

    if (resetEmail && resetEmail.length > 0) {
      setEmail(resetEmail);
    }
    if (user && user.id && prevPage === "invitation") {
      navigate(PROMO, { state: { prevPage: "account" } });
      localStorage.removeItem("popupUser");
    }
    else if (user && user.id && prevPage === SCAN) {
      // navigate(SCAN);
    }
  }, [user]);

  const handleGoogle = async () => {
    if (localStorage.getItem("newuser")) {
      localStorage.removeItem("newuser");
    }
    try {
      await HandleGoogle(auth, user);
    } catch (e) {
      alert("Une erreur s'est produite");
    }
  };

  const handleFacebook = async (e) => {
    if (localStorage.getItem("newuser")) {
      localStorage.removeItem("newuser");
    }

    try {
      const data = await HandleFacebook(auth, user);
      console.log(data.newResult.profile);
      dispatch(
        saveUser(data.newResult.profile, axiosInstance, cartItems, navigate)
      );
      navigate(ACCOUNT);
    } catch (e) {
      alert("Une erreur s'est produite");
    }
  };

  const inscription = () => {
    window.scrollTo(0, 0);
    // setPagetitle('Créer un compte gratuit')
    setState("signUp");
    if (document.getElementById("page_account"))
      document.getElementById("page_account").scrollTop = 0;
  };
  const connexion = () => {
    window.scrollTo(0, 0);
    // setPagetitle('Connexion')
    setState("signIn");
    if (forgot) setForgot(false);
    if (document.getElementById("page_account"))
      document.getElementById("page_account").scrollTop = 0;
  };

  useEffect(() => {
    const element = document.querySelector(".cercle_account_coin_border");
    if (element) {
      element.style.height = `${(user.fidelity / 70) * 100}%`;
    }
  }, [user]);

  // if (loadingConnexion) {
  //   return (<PageLoader />);
  // }
  const CheckIfFidExist = () => {
    if (fidExist) {
      setFidCompany(!fidCompany);
    }
  };
  const tutoRef = useRef();
  const closepopup = () => {
    setShowPopup(false);
  };
  const [TutoCurrentIndex, setTutoCurrentIndex] = useState(0);
  const [view, setView] = useState(false);
  const onClickHelp = () => {
    setView(!view);
    setTutoCurrentIndex(0);
  };
  const goBack =() => {
    if (previousNumberScreen == INVITATION) {navigate(NUMBER_SCREEN, { state: { prevPage: INVITATION } })}
    else {navigate(NUMBER_SCREEN);}
  }
  const [selectedItem, setSelectedItem] = useState(4);
  return (
    <>
         {
            loadingConnexion && <PageLoader />
          }
      <div id="page_account" className={`w-full h-full overflow-y-scroll ${isPWA && user._id? "pwa":""}`}>
        <NavigationMenu
          isOpen={showNavigationMenu}
          onClose={() => setShowNavigationMenu(false)}
          user={user}
        />

        <div
          className={`page_account_body pb-10${
            user && user._id ? "" : " height_normal bg-gradient3 "
          }`}
        >
          {/*{user.userLoading && <PageLoader />}*/}

          {user && user._id ? (
            <div className="flex flex-col items-start justify-start w-full h-full">
              {isLessThan_Minutes(user, 10) ? (
                <UserInfo title="Bienvenue dans notre belle communauté !" />
              ) : (
                <UserInfo title="Heureux de te revoir parmi nous !" />
              )}

              <div className="w-full h-full ">
                <div className="question-mark-account" onClick={onClickHelp}>
                  <img src={questionMark} alt="" />
                </div>
                <div className="page_account_content">
                  <PikkoPointHeader
                    user={user}
                    notEuroVersion={true}
                    isMainPage
                    onClick={() => setShowNavigationMenu(true)}
                  />
                  <BrandList
                    brands={brands}
                    originPath={ACCOUNT}
                    showTitle
                    showPromoText
                  />
                </div>
                <SwipperTuto
                  ref={tutoRef}
                  close={onClickHelp}
                  TutoCurrentIndex={TutoCurrentIndex}
                  setTutoCurrentIndex={setTutoCurrentIndex}
                  view={view}
                  slides={[
                    <div key="1" className="tuto_box">
                      <div className="tuto-container">
                        <img src={step1} alt="step1" />
                        <p className="tuto-bold">SCANNER</p>
                        <div className="tuto-text">
                          <span className="tuto-title">
                            Scanne les codes barres de tes articles pour les
                            ajouter au panier
                          </span>
                        </div>
                      </div>
                    </div>,
                    <div key="2" className="tuto_box">
                      <div className="tuto-container">
                        <img src={step2} alt="step2" />
                        <p className="tuto-bold">PAYER</p>
                        <div className="tuto-text">
                          <span className="tuto-title">
                            Paye tes courses sur ton smartphone sans faire la
                            queue
                          </span>
                        </div>
                      </div>
                    </div>,
                    <div key="3" className="tuto_box">
                      <div className="tuto-container">
                        <img src={step3} alt="step3" />
                        <p className="tuto-bold">VALIDER</p>
                        <div className="tuto-text">
                          <span className="tuto-title">
                            Dirige-toi vers la sortie express et scanne le QR
                            code de sortie
                          </span>
                        </div>
                      </div>
                    </div>,
                  ]}
                />
              </div>
            </div>
          ) : (
            <>
              <BackButton
                onClick={goBack}

                isWhite={false}
              />
              <div className="header-svg-container">
                <img
                  src={state === "signUp" ? inscriptionHeader : connexionHeader}
                  alt="header"
                  className="header-svg"
                />
              </div>

              <button
                onClick={() => {
                  state === "signUp" ? connexion() : inscription();
                }}
                className="sticky w-full my-4 text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {state === "signUp" ? (
                  <>
                    Déjà un compte ?{" "}
                    <strong className="bold_blue ">Connexion</strong>
                  </>
                ) : (
                  <>
                    Pas encore de compte ?{" "}
                    <strong className="bold_blue ">Créer un compte !</strong>
                  </>
                )}
              </button>

              <div className="flex flex-col items-start justify-start w-screen h-full p-6 ">
                <div className="flex flex-col items-center justify-start w-full py-6 gap-14">
                  <div className="flex items-center justify-center gap-8 space-x-4">
                    {/* <RenderButton/> */}

                    <button onClick={CheckIfFidExist} className="border-none">
                      {fidCompany ? (
                        <img
                          className="h-[50px] w-auto"
                          src={mailLogo}
                          alt="google"
                        />
                      ) : (
                        <img
                          className="h-[50px] w-auto "
                          src={fid_card}
                          alt="fid"
                        />
                      )}
                    </button>
                    <div
                      className="flex items-center justify-center w-full gap-4 text-3xl font-black flex-nowrap"
                      onClick={() => navigate(NUMBER_SCREEN)}
                    >
                      <img src={tel} alt="telephone" />
                    </div>
                    <button onClick={handleGoogle} className="border-none">
                      <img
                        className="h-[50px] w-auto"
                        src={logoGoogle}
                        alt="google"
                      />
                    </button>
                  </div>
                </div>
                <Email
                  user={user}
                  cartItems={cartItems}
                  ref={refComposant}
                  resetEmail={resetEmail}
                  axiosInstance={axiosInstance}
                  state={state}
                  setState={setState}
                  forgot={forgot}
                  setForgot={setForgot}
                  connexion={connexion}
                  inscription={inscription}
                  fidCompany={fidCompany}
                  setFidCompany={setFidCompany}
                  fidExist={fidExist}
                  setFidExist={setFidExist}
                />
              </div>
            </>
          )}
        </div>
        {user && user._id ? <ScanFooter originPath={ACCOUNT} /> : <></>}
      </div>
      <FooterAccount selectedItem={selectedItem} setSelectedItem={setSelectedItem} />

    </>
  );
};

export default forwardRef(AccountScreen);
